<template>
  <div class="page-schedule mb-5">
    <div class="row top-row">
      <div class="mb-3 col-md-6 tree-filters row align-items-center">
        <div class="d-none col-auto">
          <input type="date" class="input-lg" />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary btn-sm"
            @click.prevent.stop="openCheckupModal"
          >
            <img
              class="hide-dark"
              src="../assets/icons/icon-navigation-add.svg"
              alt=""
            />
            <img
              class="hide-light"
              src="../assets/icons/icon-navigation-add-light.svg"
              alt=""
            />
            <span>{{ $t("message.checkup") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row schedule-row">
      <div class="mb-3 col-lg-6 col-xl-5">
        <div class="h-100 card">
          <div class="card-header bgc-groengrijs c-200 text-white">
            <span class="overline text-sm ">{{ $t("message.checkups") }}</span>
          </div>
          <div class="card-body">
            <div class="checkups-filters row">
              <div class="mb-4 col-12 col-xl-auto">
                <search @search="handleSearch"></search>
              </div>

              <div class="mb-4 col-12 col-xl w-100">
                <div
                  class="responsive-btn-group btn-group w-100"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    :class="list_type === 'available' ? 'active' : ''"
                    @click="switchList('available')"
                    type="button"
                    class="btn-sm btn btn-secondary"
                  >
                    <span>{{ $t("message.available") }}</span>
                  </button>
                  <button
                    :class="list_type === 'planned' ? 'active' : ''"
                    @click="switchList('planned')"
                    type="button"
                    class="btn-sm btn btn-secondary"
                  >
                    <span>{{ $t("message.planned") }}</span>
                  </button>
                  <button
                    :class="list_type === 'completed' ? 'active' : ''"
                    @click="switchList('completed')"
                    type="button"
                    class="btn-sm btn btn-secondary"
                  >
                    <span>{{ $t("message.completed") }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="checkups-body position-relative"
              style="min-height: 150px"
            >
              <paginated
                class="checkups-list"
                style="min-height: 150px"
                @change="handlePagination"
                :request="request"
                :defer-loading="true"
                :loaded="contentReady"
                :total-rows="totalCount"
                :per-page="5"
              >
                <div
                  v-bind:key="checkup.id"
                  v-for="checkup in checkups"
                  :data-event="JSON.stringify(getCalEvent(checkup))"
                  :class="
                    list_type === 'available' ? 'checkup-card-draggable' : ''
                  "
                  class="card mb-3 checkup-card"
                >
                  <div
                    class="card-header pe-2 c-50"
                    :class="'bgc-' + checkupColor(checkup)"
                  >
                    <span class="text-sm overline">
                      <template v-if="list_type === 'available'">
                        <span>{{ $t("message.drag_to_calendar") }}</span>
                      </template>
                      <template v-if="list_type === 'planned'">
                        <span>{{
                          $t("message.starts_on") +
                          " " +
                          moment(checkup.start).format("YYYY-MM-DD")
                        }}</span>
                      </template>
                      <template v-if="list_type === 'completed'">
                        <span>{{
                          $t("message.ended_on") +
                          " " +
                          moment(checkup.end).format("YYYY-MM-DD")
                        }}</span>
                      </template>
                    </span>
                    <div
                      v-if="
                        list_type === 'available' ||
                        moment(checkup.start).isAfter(moment()) ||
                        !checkup.submissions
                      "
                      class="ms-auto nav"
                    >
                      <ul class="nav nav-pills p-0">
                        <li class="nav-item dropdown p-0">
                          <a
                            class="nav-link p-0"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-expanded="false"
                          >
                            <img
                              src="../assets/icons/Icon-navigation-More-vertical.svg"
                              alt=""
                            />
                          </a>
                          <ul class="dropdown-menu">
                            <li v-if="list_type === 'available'">
                              <a
                                @click="handleEditCheckup(checkup)"
                                class="dropdown-item text-sentence"
                                href="#"
                                >{{ $t("message.edit") }}</a
                              >
                            </li>
                            <li
                              v-if="
                                list_type === 'available' &&
                                !hasStartedEntry(checkup)
                              "
                            >
                              <a
                                @click="handleDeleteCheckup(checkup)"
                                class="dropdown-item text-sentence"
                                href="#"
                                >{{ $t("message.delete") }}</a
                              >
                            </li>
                            <li
                              v-if="
                                list_type === 'planned' &&
                                (moment(checkup.start).isAfter(moment()) ||
                                  !checkup.submissions)
                              "
                            >
                              <a
                                @click="handleEditEntry(checkup)"
                                class="dropdown-item text-sentence"
                                href="#"
                                >{{ $t("message.editEntry") }}</a
                              >
                            </li>
                            <li
                              v-if="
                                list_type === 'planned' &&
                                (moment(checkup.start).isAfter(moment()) ||
                                  !checkup.submissions)
                              "
                            >
                              <a
                                @click="handleDeleteCheckup(checkup)"
                                class="dropdown-item text-sentence"
                                href="#"
                                >{{ $t("message.delete") }}</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-auto pe-0">
                        <img
                          :src="
                            require(`@/assets/icons/Icon-block-checkup-32px-${checkupColor(
                              checkup
                            )}.svg`)
                          "
                          alt=""
                        />
                      </div>
                      <div class="col">
                        <!--                                                <pre>{{ checkup }}</pre>-->
                        <h5 class="mb-0">
                          {{
                            list_type !== "available"
                              ? checkup.checkup.title
                              : checkup.title
                          }}
                          <!--                          {{ checkup.title ? checkup.title : (checkup.checkup && checkup.checkup.title ? checkup.checkup.title : '') }}-->
                          <!--                          {{ (checkup.title || checkup.checkup && checkup.checkup.title ? checkup.checkup.title : '') }}-->
                        </h5>
                        <div class="text-sm mb-2">
                          {{
                            list_type !== "available"
                              ? checkup.checkup.body
                              : checkup.body
                          }}
                          <!--                          {{ (checkup.body || checkup.checkup && checkup.checkup.body ? checkup.checkup.body : '') }}-->
                        </div>
                        <div class="info-icons d-flex">
                          <!--<pre>{{checkup}}</pre>-->
                          <div class="data-point px-2 ps-0">
                            <img
                              src="../assets/icons/Icon-sidebar-tree.svg"
                              alt=""
                            />&nbsp;{{ countUsers(checkup, "team") }}
                          </div>
                          <div class="data-point px-2">
                            <img
                              src="../assets/icons/Icon-sidebar-segments.svg"
                              alt=""
                            />&nbsp;{{ countUsers(checkup, "segment") }}
                          </div>
                          <div class="data-point px-2">
                            <img
                              src="../assets/icons/Icon-sidebar-Users.svg"
                              alt=""
                            />&nbsp;{{ countUsers(checkup, "zergling") }}
                          </div>
                          <div class="data-point px-2">
                            <img
                              src="../assets/icons/Icon-tree-manager-profile.svg"
                              alt=""
                            />&nbsp;{{ countUsers(checkup, "overlord") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </paginated>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-block mb-3 col-lg-6 col-xl-7">
        <div class="h-100 card">
          <div class="card-header bgc-groengrijs c-50">
            <span class="overline text-sm ">{{ $t("message.calendar") }}</span>
          </div>
          <div class="card-body">
            <div class="position-relative">
              <div id="fullcalendar-schedule"></div>
              <div
                v-show="!calEventsLoaded"
                class="
                  position-absolute
                  top-50
                  start-50
                  translate-middle
                  container-spinner
                "
              >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">{{ $t("message.loading") }}...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <button @click="initDragContainer">drag</button>-->
    </div>
  </div>
</template>
<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import CheckupModal from "@/views/components/modals/CheckupModal";
import { Popover } from "bootstrap";
import Search from "@/views/components/simple/Search";
import moment from "moment";
import Paginated from "@/views/components/simple/Paginated";
import qs from "qs";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import CheckupEntry from "@/views/components/modals/CheckupEntry";
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';

export default {
  components: { Paginated, Search },
  updated() {
    this.$nextTick(function () {
      this.initDragContainer();
    });
  },
  methods: {
    hasStartedEntry(checkup) {
      return (
        checkup.checkupentries &&
        checkup.checkupentries.find(
          (entry) => moment(entry.start).unix() < moment().unix()
        )
      );
    },
    async deleteConfirmed(id) {
      if (this.list_type == "available") {
        await this.axios.delete("/checkups/" + id);
      } else {
        await this.axios.delete("/checkupentries/" + id);
      }
      this.getCheckups();
    },
    async handleEditEntry(checkup) {
      this.openCheckupEntryModal({
        id: checkup.id,
        title: checkup.checkup.title,
        start: checkup.start,
        end: checkup.end,
        duration: checkup.checkup.duration,
        grace: checkup.checkup.graceperiod,
      });
    },
    async handleDeleteCheckup(checkup) {
      //console.log('delete', checkup)
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: checkup.id,
          title:
            this.list_type == "available"
              ? checkup.title
              : checkup.checkup.title,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.deleteConfirmed,
        },
      });
    },
    async switchList(type) {
      this.checkups = [];
      this.list_type = type;
      // await this.getCheckups()
      await this.handleSearch(this.search);
    },
    // async getPlannedCheckups(type) {
    //   this.checkupsLoaded = false;
    //   const get = await this.axios.get('/checkups/' + type + '?_sort=start:asc') //to get for specific teams: &checkup.swarms_contains=3
    //   this.checkupsLoaded = true;
    //   return get.data
    // },
    async handlePagination(data) {
      // this.checkupsLoaded = false;
      // console.log('pagination checkup', data)
      if (this.list_type !== "available") {
        for (const checkup of data) {
          checkup.enddate = moment(checkup.enddate);
          // checkup.pct = (100 * checkup.users.filledin.length) / checkup.users.totalusers.length;
        }
      }

      if (this.list_type !== "available") {
        const filledCheckups = await Promise.all(
          await data.map(async (checkup) => {
            const get = await this.axios.get("/checkups/" + checkup.checkup);
            const entry = get.data;
            checkup.checkup = entry;
            return checkup;
          })
        );
        this.checkups = filledCheckups;
      } else {
        this.checkups = data;
      }
      //
      this.checkupsLoaded = true;
      this.contentReady = true;

      // if (this.checkups.length > 0) {
      //   this.setActiveCheckup(this.checkups[0])
      // }
    },
    checkupColor(checkup) {
      // console.log('checkupc', checkup)
      // console.log('checkup',checkup)
      let c = "koraalrood";
      if (checkup.color) {
        c = checkup.color;
      } else if (checkup.checkup && checkup.checkup.color) {
        c = checkup.checkup.color;
      }
      return c;
    },
    countUsers(checkup, type) {
      let c = 0;

      if (!checkup.checkup) {
        checkup.swarms.forEach((sw) => {
          if (type === "zergling" || type === "overlord") {
            c += sw[type + "count"];
          } else {
            type === "team"
              ? sw.issegment !== true
                ? c++
                : false
              : sw.issegment === true
              ? c++
              : false;
          }
        });
      } else {
        c += checkup.checkup[type + "count"];
      }

      return c;
    },
    getCalEvent(checkup) {
      return {
        id: checkup.id,
        title: checkup.title,
        display: "block",
        allDay: true,
        body: checkup.body,
        grace: checkup.graceperiod,
        duration: { days: checkup.duration + checkup.graceperiod + 1 },
        backgroundColor: this.getColorCode(checkup.color),
        borderColor: this.getColorCode(checkup.color),
        checkup: checkup,
      };
    },
    handleEditCheckup(checkup) {
      this.openCheckupModal(checkup);
    },
    async handleSearch(val) {
      this.contentReady = false;
      this.search = val;
      let s = "title_contains=" + val;
      if (this.list_type !== "available") {
        s = "checkup.title_contains=" + val;
      }
      await this.getCheckups(s);
    },
    openCheckupModal(checkup = null) {
      this.popOvers.forEach((popover) => {
        popover.hide();
      });
      this.$store.dispatch("openModal", {
        component: CheckupModal,
        properties: { checkup: checkup },
        options: {
          centered: false,
        },
        events: {
          submitCheckup: this.submitCheckup,
        },
      });
    },
    initCalendarData() {
      //todo: fill calendar with already planned events
    },
    postCheckupEntry(event, calendar) {
      //console.log(event.end, moment(event.start).add(event.extendedProps.checkup.duration, "days").toISOString());
      this.axios
        .post("/checkupentries", {
          start: event.start,
          end: moment(event.start)
            .add(event.extendedProps.checkup.duration + 1, "days")
            .toISOString(), // Added extra day, takes start of the last day otherwise
          grace: event.end,
          checkup: event.id,
        })
        .then(() => {
          calendar.removeAllEvents();
          calendar.refetchEvents();
          calendar.render();
        });
    },
    async getCalendarEvents(start, end) {
      //console.log('range', start, end)
      this.calEventsLoaded = false;

      const q = qs.stringify({
        _where: {
          _or: [
            {
              start_lt: end,
              end_gte: start,
            },
            {
              start_gte: start,
              start_lte: end,
            },
          ],
        },
      });

      //start before monthEnd && end after monthStart
      //start after monthStart && before monthEnd
      ////end after monthStart && before monthEnd

      // const get = await this.axios.get('/checkupentries?start_gte=' + moment(start).format('YYYY-MM-DD') + '&end_lte=' + moment(end).format('YYYY-MM-DD'))

      const get = await this.axios.get("/checkupentries?" + q);

      const entries = get.data;
      let events = entries.map((entry) => {
        let event = this.getCalEvent(entry.checkup);
        event.start = entry.start;
        event.end = entry.grace || entry.end; // Legacy purposes for events without grace period calculated in
        event.entryid = entry.id;
        event.submissions = entry.submissions;
        return event;
      });
      this.calEventsLoaded = true;

      // console.log('events got', events)
      return events;
    },
    initDragContainer() {
      const containerEl = document.querySelector(".checkups-list");
      if (
        this.list_type === "available" &&
        this.calendarLoaded &&
        this.checkups.length > 0 &&
        !containerEl.classList.contains("is-draggable")
      ) {
        containerEl.classList.add("is-draggable");
        new Draggable(containerEl, {
          itemSelector: ".checkup-card-draggable",
        });
      }
    },

    initCalendar() {
      let _this = this;
      this.calendarLoaded = false;
      const calendarEl = this.$el.querySelector("#fullcalendar-schedule");

      let calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        locales: [frLocale, nlLocale],
        locale: this.$i18n.locale,
        droppable: true,
        events: function (info, successCallback, failureCallback) {
          try {
            // console.log('successcb', successCallback)
            return _this.getCalendarEvents(
              info.start.valueOf(),
              info.end.valueOf()
            );
          } catch (e) {
            failureCallback(e);
          }
        },
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "dayGridMonth,listMonth",
        },
        aspectRatio: window.outerWidth < 1025 ? 0.5 : 1.35,
        eventReceive: function (info) {
          // console.log('eventReceive', info)
          info.event.setEnd(
            moment(info.event.start).add(
              info.event.extendedProps.duration + "days"
            )
          );
          _this.postCheckupEntry(info.event, calendar);
          // calendar.removeAllEvents();
        },
        eventDidMount: function (info) {
          var allowListPop = Popover.Default.allowList;
          allowListPop.button = [];
          allowListPop.img = ["src"];

          const popover = new Popover(info.el, {
            title: ``,
            // content: info.event.extendedProps.body,
            content: _this.getPopOverContent(info.event),
            placement: "left",
            html: true,
            allowList: allowListPop,
            // trigger: "hover",
            // container: "body"
          });
          info.el.addEventListener("shown.bs.popover", function () {
            // let id = `event-${info.event.id}`;
            let entry_btn_class = `button.edit-entry`;
            let entry_btn = document.querySelector(entry_btn_class);

            if (entry_btn) {
              entry_btn.addEventListener("click", function () {
                _this.openCheckupEntryModal({
                  id: info.event.extendedProps.entryid,
                  start: info.event.start,
                  end: info.event.end,
                  title: info.event.title,
                  duration: info.event.extendedProps.checkup.duration,
                  grace: info.event.extendedProps.checkup.graceperiod,
                });
              });
            }

            let btn_class = `button.edit-event`;
            let btn = document.querySelector(btn_class);
            if (btn) {
              btn.addEventListener("click", function () {
                // console.log('opening event from cal', info.event)
                _this.openCheckupModal(info.event.extendedProps.checkup); //todo pass event to modal as strapi object, not fc event object
              });
            }
          });

          info.el.addEventListener("show.bs.popover", function () {
            // console.log('showing popover')
            _this.popOvers.forEach((popoverB) => {
              popoverB.hide();
            });
          });
          _this.popOvers.push(popover);
        },
      });

      this.mitt.on('langChanged', () => {
        calendar.setOption('locale', this.$i18n.locale);
      })

      // calendar.removeAllEvents();
      // calendar.refetchEvents();
      calendar.render();
      this.calendarLoaded = true;
      // this.initDragContainer()
    },
    getPopOverContent(event) {
      return `
                <div class="row">
                  <div class="border-bottom pb-2 mb-2  col-12 d-flex justify-content-between align-items-center">
                    <h5 class="mb-0">${event.title}</h5>
                     ${
                       moment(event.start).isAfter(moment()) ||
                       !event.extendedProps.submissions
                         ? `<button id="entry-${event.extendedProps.entryid}" class="p-0 btn btn-link edit-entry">
                        <i class="fas fa-calendar c-theme c-200"></i>
                    </button>`
                         : ""
                     }
                    <button id="event-${
                      event.id
                    }" class="p-0 btn btn-link edit-event">
                        <img src="${require(`@/assets/icons/Icon-checkup-edit.svg`)}" alt="edit-popover">
                    </button>
                  </div>
                  <div class="col-4">Starts</div>
                  <div class="col-8">${moment(event.start).format(
                    "YYYY-MM-DD"
                  )}</div>
                  <div class="col-4">Ends</div>
                  <div class="col-8">${moment(event.end).format(
                    "YYYY-MM-DD"
                  )}</div>
                  <div class="col-4">Grace</div>
                  <div class="col-8">${
                    event.extendedProps.grace + " " + this.$t("message.days")
                  }</div>
                </div>
                `;
    },

    async getCheckups(filter = "") {
      // console.log('get')
      this.checkupsLoaded = false;
      this.totalCount = 0;
      let q = "";
      if (filter) {
        if (this.list_type !== "available") {
          filter = "&" + filter;
        } else {
          filter = "&" + filter;
        }
      }

      if (this.list_type !== "available") {
        if (this.list_type === "completed") {
          this.request =
            "/checkups/" + this.list_type + "?_sort=end:desc" + filter;
        } else {
          this.request =
            "/checkups/" + this.list_type + "?_sort=start:asc" + filter;
        }
      } else {
        this.request = "/checkups?_sort=created_at:desc" + filter;
      }

      if (this.list_type !== "available") {
        q = "/checkups/" + this.list_type + "/count?_sort=start:asc" + filter;
      } else {
        q = "/checkups/count?_sort=created_at:desc" + filter;
      }
      const count = await this.axios.get(q);
      this.totalCount = count.data;

      this.checkupsLoaded = true;

      this.initCalendar();
      this.initCalendarData();
      this.initDragContainer();
    },
    async submitCheckup(checkup) {
      // console.log('submitting checkup', checkup)
      if (checkup.id) {
        await this.axios.put("/checkups/" + checkup.id, checkup);
      } else {
        await this.axios.post("/checkups", checkup);
      }

      await this.getCheckups();
    },

    async submitEntry(entry) {
      //console.log(entry);
      await this.axios.put("/checkupentries/" + entry.id, entry);
      await this.getCheckups();
    },

    async openCheckupEntryModal(entry) {
      this.popOvers.forEach((popover) => {
        popover.hide();
      });
      this.$store.dispatch("openModal", {
        component: CheckupEntry,
        properties: { entry },
        options: {
          centered: false,
        },
        events: {
          submitEntry: this.submitEntry,
        },
      });
    },
  },
  // watch: {
  //   async checkups() {
  //     const filledCheckups = await Promise.all(await this.checkups.map(async (checkup) => {
  //       const get = await this.axios.get('/checkups/' + checkup.checkup)
  //       const entry = get.data
  //       checkup.checkup = entry;
  //       return checkup
  //     }))
  //     this.checkups = filledCheckups
  //     //for each checkup, get referenced checkup entity and set in .checkup key
  //   }
  // },
  data() {
    return {
      request: "/checkups?_sort=created_at:desc",
      totalCount: 0,
      calendarLoaded: false,
      calEventsLoaded: false,
      checkupsLoaded: false,
      moment: moment,
      popOvers: [],
      checkups: [],
      checkupsPlanned: [],
      list_type: "available",
      search: "",
      contentReady: false,
      calendar: null,
    };
  },

  async mounted() {
    this.mitt.emit("loadcomplete", { complete: true });
    await this.getCheckups();
    this.initCalendar();
    this.initCalendarData();
  },
  beforeUnmount() {
    this.mitt.off('langChanged')
  }
};
</script>

<style lang="scss" scoped>
.checkup-card {
  border-radius: 8px !important;

  .card-header {
    cursor: grab;
    user-select: none;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}
</style>

<style>
.fc-event {
  cursor: pointer;
}

.responsive-btn-group {
}
</style>