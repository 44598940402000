<template>
  <form class="modal-content" @submit.prevent="submitEntry">
    <div class="modal-header p-25 d-flex justify-content-between align-items-center">
      <div class="modal-title w-100 flex-grow-1">
        <h5 class="mb-0 text-sentence">{{$t('message.edit_entry_for')}}: {{ entry.title }}</h5>
      </div>
      <button tabindex="-1" type="button" class="d-block ms-auto btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t('message.start_date') }}</label>
        <input @change="calculateEnd" v-model="data.start" type="date" class="input-lg w-100">
      </div>
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t('message.end_date') }}</label>
        <div class="py-2 w-100">{{ moment(data.end).format('DD-MM-YYYY') }}</div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
      <div v-if="isValid">
        <button v-if="!loading" type="submit" class="btn btn-primary">{{ $t("message.save") }}</button>
        <button v-else class="btn disabled d-flex " type="button" disabled>
          <div style="height: 12px;width: 12px;" class="spinner-border" role="status">
            <span class="visually-hidden">{{ $t('message.loading') }}</span>
          </div>
          <span class="ms-2 text-sentence">{{ $t('message.submitting') }}</span>
        </button>
      </div>
      <div v-else>
        <button type="submit" class="btn disabled btn-primary" disabled><span class="text-sentence">{{ $t("message.save") }}</span></button>
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
export default {
  props: ['entry'],
  data() {
    return {
      loading: false,
      data: {
        id: this.entry.id,
        start: moment(this.entry.start).format('YYYY-MM-DD'),
        end: this.entry.end,
      },
      duration: this.entry.duration,
      grace: this.entry.grace,
      moment,
    }
  },
  computed: {
    isValid() {
      return moment(this.data.start).isAfter(moment());
    }
  },
  methods: {
    calculateEnd() {
      this.data.end = moment(this.data.start).add((this.duration + this.grace), 'days').format('YYYY-MM-DD');
    },
    submitEntry() {
      this.loading = true;
      if (this.isValid) {
        this.$emit('submitEntry', this.data);
        this.$store.dispatch('closeModal');
      }
      this.loading = false;
    }
  }
}
</script>