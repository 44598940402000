<template>
  <form class="modal-content" @submit.prevent="submitModal()">
    <!--<pre>{{checkup}}</pre>-->
    <div
      class="
        modal-header
        p-25
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="modal-title w-100 flex-grow-1">
        <input
          v-model="title"
          :placeholder="$t('message.add_title')"
          type="text"
          required
          class="input-xl w-100"
        />
      </div>
      <button
        tabindex="-1"
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="filter-row mb-3">
        <!--        <label for="">{{ $t('message.add_a_filter') }}</label>-->
        <textarea
          required
          v-model="body"
          :placeholder="`${$t('message.description')}...`"
          name=""
          id=""
          class="w-100"
          rows="3"
        ></textarea>
      </div>
      <div class="filter-row mb-3">
        <div class="row">
          <div class="col-md-6">
            <label for="" class="text-sentence"
              >{{ $t("message.duration") }} ({{ $t("message.days") }})</label
            >
            <input
              required
              :placeholder="$t('message.duration')"
              class="w-100"
              type="number"
              v-model="duration"
            />
          </div>
          <div class="col-md-6">
            <label for="" class="text-sentence"
              >{{ $t("message.graceperiod") }} ({{ $t("message.days") }})</label
            >
            <input
              required
              :placeholder="$t('message.graceperiod')"
              class="w-100"
              type="number"
              v-model="graceperiod"
            />
          </div>
        </div>
      </div>
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t("message.add_a_team_or_segment") }}</label>
      </div>
      <div class="selected-swarms">
        <div
          class="d-flex justify-content-between blocks"
          style="max-height: 250px; overflow-y: auto"
          v-for="(swarm, index) in swarms"
          :class="index > 0 ? 'border-top' : ''"
          v-bind:key="swarm"
        >
          <div
            class="block d-flex p-2"
            :class="index > 0 ? 'border-bottom' : ''"
          >
            <div
              style="height: 2rem; width: 2rem"
              :class="swarm.issegment ? 'bgc-magenta' : 'bgc-okergeel'"
              class="
                me-3
                flex-shrink-0
                block-icon
                rounded-3
                d-flex
                justify-content-center
                align-content-center
              "
            >
              <img
                v-if="swarm.issegment"
                :src="require(`@/assets/icons/Icon-block-segment-32px.svg`)"
                alt=""
              />
              <img
                v-else
                :src="require(`@/assets/icons/Icon-block-team-32px.svg`)"
                alt=""
              />
            </div>
            <div class="block-info">
              <h5 class="mb-0">{{ swarm.title }}</h5>
              <div class="text-sm">
                {{ swarm.zerglingcount }} {{ $t("message.users") }} -
                {{ swarm.overlordcount }} {{ $t("message.manager") }}
              </div>
            </div>
          </div>
          <img
            class="cursor-pointer"
            @click="swarms.splice(index, 1)"
            src="../../../assets/icons/icon-navigation-close.svg"
            alt=""
          />
        </div>
      </div>

      <div
        class="
          add-filter
          bgc-theme
          c-200
          py-2
          px-3
          mb-3
          border-top border-bottom
          position-relative
        "
      >
        <div class="d-flex justify-content-between">
          <select-search
            class="me-2 flex-grow-1"
            :value="'id'"
            :label="'title'"
            :ajax="true"
            :query="'/swarms'"
            @selectOption="handleAddSwarm"
            :placeholder="$t('message.search_teams_or_segments')"
          ></select-search>
        </div>
      </div>
      <div class="filter-row d-flex mb-3">
        <checkbox v-model="childteams" /><span class="text-sentence">{{
          $t("message.add_subteams")
        }}</span>
      </div>
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t("message.add_a_checkup_block") }}</label>
      </div>
      <div class="selected-blocks">
        <div
          class="d-flex justify-content-between blocks"
          style="max-height: 250px; overflow-y: auto"
          v-for="(block, index) in checkupblocks"
          :class="index > 0 ? 'border-top' : ''"
          v-bind:key="block"
        >
          <div
            :class="index > 0 ? 'border-top' : ''"
            class="block d-flex p-2"
            v-bind:key="block"
          >
            <div
              style="height: 2rem; width: 2rem"
              class="
                me-3
                flex-shrink-0
                bgc-groen
                c-200
                block-icon
                rounded-3
                d-flex
                justify-content-center
                align-content-center
              "
            >
              <span class="h3 text-white">{{ block.title.charAt(0) }}</span>
            </div>
            <div class="block-info">
              <h5 class="mb-0">{{ block.title }}</h5>
              <div class="text-sm">
                <span class="text-sentence">{{ block.questioncount }} {{ $t("message.questions") }} -
                {{ block.estimatedtime }} {{ $t("message.min") }}.</span>
              </div>
            </div>
          </div>
          <img
            class="cursor-pointer"
            @click="checkupblocks.splice(index, 1)"
            src="../../../assets/icons/icon-navigation-close.svg"
            alt=""
          />
        </div>
      </div>
      <div
        class="add-filter bgc-theme c-200 py-2 px-3 border-top border-bottom"
      >
        <div class="d-flex justify-content-between">
          <select-search
            class="me-2 flex-grow-1"
            :value="'id'"
            :label="'title'"
            :ajax="true"
            :query="'/checkupblocks?_sort=weight:asc'"
            :show-focus="5"
            @selectOption="handleAddBlock"
            :placeholder="$t('message.search_blocks')"
          ></select-search>
          <!--        <button @click.stop.prevent="showBlockPopup" class="btn btn-secondary btn-sm">-->
          <!--          <img class="hide-dark" src="../../../assets/icons/icon-navigation-add.svg" alt="">-->
          <!--          <img class="hide-light" src="../../../assets/icons/icon-navigation-add-light.svg" alt="">-->
          <!--          {{ $t('message.block') }}-->
          <!--        </button>-->
        </div>
      </div>
    </div>
    <div class="p-25 modal-footer d-flex justify-content-between border-top-0">
      <div class="colorpicker">
        <label for="" class="text-sentence">{{ $t("message.pick_a_color") }}</label>
        <div class="colors d-flex">
          <span
            v-for="color in colors"
            v-bind:key="color"
            class="mb-0 me-1 flex-grow-0 color"
          >
            <input
              :id="'c-' + color"
              type="radio"
              class="d-none"
              :value="color"
              name="color"
              v-model="checkupcolor"
            />
            <label
              class="c-200 color-label text-sentence"
              :class="'bgc-' + color"
              :for="'c-' + color"
              style="height: 1.6rem; width: 1.6rem; border-radius: 4px"
            >
            </label>
          </span>
        </div>
      </div>

      <div class="actions">
        <!--       <button type="button" class="me-2 btn btn-secondary" data-bs-dismiss="modal">{{ $t("message.cancel") }}</button>-->
        <button v-if="!loading" type="submit" class="btn btn-primary">
          <span class="text-sentence">{{ $t("message.save") }}</span>
        </button>
        <button v-else class="btn disabled d-flex" type="button" disabled>
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t("message.loading") }}</span>
          </div>
          <span class="ms-2 text-sentence">{{ $t("message.submitting") }}</span>
        </button>
      </div>
      <p class="w-100 c-koraalrood c-200" v-if="error">{{ error }}</p>
    </div>
  </form>
</template>
<script>
import SelectSearch from "@/views/components/simple/SelectSearch";
import Checkbox from "@/views/components/simple/Checkbox";
import ConfirmCheckupCopyModal from "@/views/components/modals/ConfirmCheckupCopyModal";

export default {
  components: { SelectSearch, Checkbox },
  props: ["checkup"],
  created() {
    this.getSwarms();
  },
  data() {
    return {
      loading: false,
      checkupcolor: this.checkup ? this.checkup.color : null,
      title: this.checkup ? this.checkup.title : null,
      originalTitle: this.checkup ? this.checkup.title : null,
      body: this.checkup ? this.checkup.body : null,
      duration: this.checkup ? this.checkup.duration : null,
      graceperiod: this.checkup ? this.checkup.graceperiod : null,
      repeatafter: this.checkup ? this.checkup.repeatafter : null,
      childteams: this.checkup ? this.checkup.childteams : null,
      swarms: this.checkup.swarms || [],
      checkupblocks: this.checkup.checkupblocks || [],
      blockPopupShown: false,
      teamPopupShown: false,
      swarmList: [],
      error: "",
    };
  },
  methods: {
    handleAddSwarm(swarm) {
      !this.swarms.includes(swarm) ? this.swarms.push(swarm) : false;
    },
    handleAddBlock(block) {
      !this.checkupblocks.includes(block)
        ? this.checkupblocks.push(block)
        : false;
    },
    validModal() {
      let v = true;

      if (!this.checkupcolor) {
        v = false;
      }
      if (this.swarms.length < 1) {
        v = false;
      }
      if (this.checkupblocks.length < 1) {
        v = false;
      }

      return v;
    },
    submitModal() {
      this.loading = true;
      // console.log('submitting modal checkup')
      if (this.validModal()) {
        if (
          this.checkup.id &&
          this.checkup.checkupentries.find(
            (entry) => new Date(entry.start).getTime() < new Date().getTime()
          )
        ) {
          // console.log("checkup has started entries");
          return this.$store.dispatch("openModal", {
            component: ConfirmCheckupCopyModal,
            properties: {
              checkup: this.checkup,
            },
            options: {
              centered: true,
            },
            events: {
              submitModal: () => {
                this.$emit("submitCheckup", {
                  id: this.checkup ? this.checkup.id : null,
                  title:
                    this.originalTitle == this.title
                      ? this.title + " (COPY)"
                      : this.title,
                  body: this.body,
                  duration: this.duration,
                  graceperiod: this.graceperiod,
                  repeatafter: this.repeatafter,
                  childteams: this.childteams,
                  toCopy: true,
                  swarms: this.swarms.map((i) => {
                    return i.id;
                  }),
                  color: this.checkupcolor,
                  checkupblocks: this.checkupblocks.map((i) => {
                    return i.id;
                  }),
                });
                this.$store.dispatch("closeModal");
              },
            },
          });
        }

        this.$emit("submitCheckup", {
          id: this.checkup ? this.checkup.id : null,
          title: this.title,
          body: this.body,
          duration: this.duration,
          graceperiod: this.graceperiod,
          repeatafter: this.repeatafter,
          childteams: this.childteams,
          swarms: this.swarms.map((i) => {
            return i.id;
          }),
          color: this.checkupcolor,
          checkupblocks: this.checkupblocks.map((i) => {
            return i.id;
          }),
        });
        this.$store.dispatch("closeModal");
      } else {
        this.error = this.$t("message.fill_all_fields");
      }

      this.loading = false;
    },
    async getSwarms() {
      const request = await this.axios.get("/swarms");
      this.swarmList = request.data;
    },
    showBlockPopup() {
      this.blockPopupShown = true;
      this.teamPopupShown = false;
    },
    showTeamPopup() {
      this.blockPopupShown = false;
      this.teamPopupShown = true;
    },
  },
};
</script>

<style lang="scss">
</style>